import "styles/pages/page-akademia-ekspertow.scss";
import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "../components/Breadcrumbs";

const PageAcademia = ({ pageContext }) => {
	const pageAcademy = pageContext.pageContent;

	return (
		<Layout headerColor="#9300B9">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/akademia-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Akademia ekspertów" />
			<section className="academia">
				<div className="container">
					<h2
						className="section-title"
						dangerouslySetInnerHTML={{
							__html: pageAcademy.academyTitle,
						}}
					/>
					<div className="row">
						<div className="col-xl-6">
							<div
								className="academia__text"
								dangerouslySetInnerHTML={{
									__html: pageAcademy.academyDesc1,
								}}
							></div>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageAcademy.academyImg1?.sourceUrl}
								alt=""
								className="img-fluid academia__img-1"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageAcademia;
